import axios from "axios";
import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import "./index.css";

import { useNavigate, useParams } from "react-router-dom";


import { authHeader } from "../../utils";

const EventCategoryDetails = () => {
  const [events, setEvents] = useState([]);

  const { categoryId } = useParams();

  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const url = "https://bcs-confidence-api.prostuti.net/api/react/event-categories";

    axios
      .get(url+"/"+categoryId, { headers: authHeader() })
      .then(function (response) {

        console.log(response);
        const { data } = response;
        setEvents(data?.data?.events || []);
        setErrorMessage("");
        setLoading(false);
      })
      .catch(function (error) {
        setErrorMessage(error.response.data.message);
        setLoading(false);
      });
  }, []);

  const goToEventDetails = (e, eventId) => {
    e.preventDefault();
    navigate("/eventDetails/" + eventId);
  };

  console.log("events", events);
  

  let toBeRenderedElement = (
    <FallingLines
      color="#4fa94d"
      width="100"
      visible={true}
      ariaLabel="falling-lines-loading"
    />
  );

  if (!loading && errorMessage.length && errorMessage == "Unauthenticated.") {
    toBeRenderedElement = <p>You must login to view your events.</p>;
  } else if (!loading && events && events.length) {
    toBeRenderedElement = events
      // .slice(1)
      .filter((item) => item.status == "live")
      .map((item, i) => {
        // if (i === 0) {
        //   return (
        //     <li class={`nextStep ${i == 0 ? "current" : ""}`} data-id={item.id}>
        //       {/* <a onClick={(ev)=> goToEventDetails(ev, item.id)} href="#"> {item.event_name}  </a> */}

        //       <button
        //         class="button-72 my-3"
        //         role="button"
        //         onClick={(ev) => goToEventDetails(ev, item.id)}
        //         href="#"
        //       >
        //         <i class="fas fa-school mx-2"></i>
        //         {item.event_name}
        //       </button>
        //     </li>
        //   );
        // }

        return (
          <li class={`nextStep ${i == 0 ? "current" : ""}`} data-id={item.id}>
              {/* <a onClick={(ev)=> goToEventDetails(ev, item.id)} href="#"> {item.event_name}  </a> */}

              <button
                class="button-72 my-3"
                role="button"
                onClick={(ev) => goToEventDetails(ev, item.id)}
                href="#"
              >
                <i class="fas fa-school mx-2"></i>
                {item.title}
              </button>
            </li>
        )
      });
  } else if (!loading && events && !events.length) {
    toBeRenderedElement = <p>No event found for your class</p>;
  } else {
    toBeRenderedElement = (
      <FallingLines
        color="#4fa94d"
        width="100"
        visible={true}
        ariaLabel="falling-lines-loading"
      />
    );
  }

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>প্রস্তুতিতে প্রস্তুত হোন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
              <div class="attend_quiz_item">
                <div class="img">
                  <img
                    class="img-fluid w-sm-50 w-md-100"
                    src="https://bcs-confidence.prostuti.net/admin/media/logo/logo-63a43964406bc.png"
                    alt=""
                  />
                </div>
                <div class="text">
                  <h2>প্রস্তুতি সমূহ</h2>
                  {/* <h4>আপনার পছন্দের ক্যাটাগরি নির্বাচন করে প্রস্তুতি শুরু করুন-</h4> */}
                  <ul>
                    {toBeRenderedElement}
                    {/* Exams will be available after 15th April 2023 */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventCategoryDetails;
